import { useState } from "react"

export default function Code(){

    const [pass, setPass] = useState('');
    const [msg, setMsg] = useState('');

    const handleEnter = ()=>{
        pass === 'password' ? window.location.href = '/game' : setMsg('Incorrect password') ;
    }

    return(
        <div className="container">
            <div className="row mt-5" id="game_2" >
                <div className="col col-12">
                    <input type="password" placeholder="PASSWORD" className="form-control" onChange={(e)=>{setPass(e.target.value)}} value={pass} />
                    <span className="err">
                        {msg}
                    </span>
                    <br />
                    <button className="mt-3 btn" onClick={handleEnter}>
                        Betreed het spel.
                    </button>
                </div>
            </div>
        </div>
    )
}