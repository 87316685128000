import { useEffect, useState } from "react";
import { requestApi } from '../../utils/request';

export default function Game() {

    const [msg, setMsg] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [playerCurent, setPlayerCurent] = useState([]);
    const [players, setPlayers] = useState([]);
    const [color, setColor] = useState({ r: 0, g: 0, b: 0 });
    const [currentGain, setCurrentGain] = useState(null);
    const [index, setIndex] = useState(0);
    const [btnBack, setBtnBack] = useState(false);

    const [gains, setGains] = useState({
        Le_Flambant: {
            name: 'Le_Flambant',
            image_uri: 'https://api-golf.treeoskiframes.website/img/3.png',
            played: false,
        },
        MG_weekend: {
            name: 'MG_weekend',
            image_uri: 'https://api-golf.treeoskiframes.website/img/4.png',
            played: false,
        },
        Valiente: {
            name: 'Valiente',
            image_uri: 'https://api-golf.treeoskiframes.website/img/0.png',
            played: false,
        },
        Valiente_2: {
            name: 'Valiente_2',
            image_uri: 'https://api-golf.treeoskiframes.website/img/0.png',
            played: false,
        },
        Pure_CBD: {
            name: 'Pure_CBD',
            image_uri: 'https://api-golf.treeoskiframes.website/img/5.png',
            played: false,
        },
        Peter_Engels: {
            name: 'Peter_Engels',
            image_uri: 'https://api-golf.treeoskiframes.website/img/6.png',
            played: false,
        },
        Shine_Leathercosmetics: {
            name: 'Shine_Leathercosmetics',
            image_uri: 'https://api-golf.treeoskiframes.website/img/7.png',
            played: false,
        },
        Valientea: {
            name: "Valientea",
            image_uri: "https://api-golf.treeoskiframes.website/img/1.png",
            played: false,
        }
    });

    const play = async () => {
        setBtnDisabled(true);
        const res = await requestApi('/play', 'post', { gain: currentGain.name });
        if (res.data.success) {
            setPlayers(res.data.data.players);
        } else {
            setMsg(res.data.msg);
        }
    }

    const nextPlayer = () => {
        setPlayerCurent(players[randomInt(0, players.length)]);
        setColor({ r: randomInt(0, 200), g: randomInt(0, 200), b: randomInt(0, 200) })
    }

    const playRandom = () => {
        console.log(players);
        let count = 0;
        while (count < 120) {
            setTimeout(() => { nextPlayer() }, (count * 120) + count);
            ++count;
        }
        setTimeout(() => { setBtnDisabled(false); setBtnBack(true) }, 20000);
    }

    const changeGain = (gain_click) => {
        console.log({ gain_click });
        if (!gain_click.played) {
            setCurrentGain(gain_click);
            const updatedGain = { ...gains[gain_click.name], played: true };
            setGains({
                ...gains,
                [gain_click.name]: updatedGain,
            });
        }
    }

    useEffect(() => {
        playRandom();
    }, [players]);

    if (msg) {
        return (
            <div>
                <h1 className="err">{msg}</h1>
                <button className="btn mt-5" onClick={() => { window.location.href = '/'; }} disabled={btnDisabled}>
                    Back
                </button>
            </div>
        )
    }

    if (!currentGain) {
        return (
            <div className="container position-absolute top-50 start-50 translate-middle">
                <div className="row">
                    {
                        Object.entries(gains).map((gain) => {
                            const gs = gain[1].played ? 0.4 : 1;
                            return (
                                <div className="col col-3 mt-3" onClick={() => { changeGain(gain[1]); }}>
                                    <img alt={gain[1].name} className="" style={{ borderRadius: '2em', opacity: gs, objectFit: 'cover' }} width={'100%'} height={'100%'} src={gain[1].image_uri} />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        )
    }

    if (!players.length) {
        return (
            <div className="text-center" id="game">
                <div className="container mt-5 text-center">
                    <span className="pulse">
                        Winner...
                    </span>
                </div>
                <div className="play text-center">
                    <button className="btn mt-5" style={{ margin: 'auto' }} onClick={async () => { await play(); }} disabled={btnDisabled}>
                        Play
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="pulse win" style={{ opacity: !btnDisabled ? 100 : 0, transitionDuration: '1s' }}>
                {
                    (currentGain && !btnDisabled) ?
                        <img src={currentGain.image_uri} width={'100%'} height={'auto'} />
                        :
                        ''
                }
            </div>
            <div className="text-center" id="game">
                <div className="container mt-5 pulse" style={{ color: 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')' }}>
                    {
                        (playerCurent) ? playerCurent.nom + ' ' + playerCurent.prenom : ''
                    }
                </div>
                <div className="play position-fixed" style={{ width: '400px' }}>
                    <button style={btnBack ? { opacity: 1 } : { opacity: 0 }} disabled={btnDisabled} className="mt-3 btn" onClick={play}>
                        Het trekken opnieuw starten
                    </button>
                    <button
                        onClick={async () => {
                            try {
                                // Assurez-vous que requestApi est définie ailleurs dans votre code
                                await requestApi('/encodeWinner', 'post', { player_id: playerCurent.id, gain_name: currentGain.name });

                                // Mettez à jour l'état
                                setPlayerCurent(null);
                                setCurrentGain(null);
                                setPlayers([]);
                            } catch (error) {
                                console.error("Failed to encode winner:", error);
                            }
                        }}
                        style={btnBack ? { opacity: 1, background: 'brown' } : { opacity: 0, 'background': 'brown' }}
                        className="btn mt-3 p-3 btn-red">
                        Back
                    </button>
                    <br />
                </div>

            </div>
        </>
    )
}

function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}