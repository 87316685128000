import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../lang/i18n";
import { requestApi } from "../../utils/request";
import Papa from 'papaparse';

export default function Form() {
    const { t } = useTranslation();
    const [formDatas, setFormDatas] = useState({});
    const [msg, setMsg] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [items, setItems] = useState([]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const submit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        const res = await requestApi('/register', 'post', formDatas);
        if (res.data.success) {
            setMsg(t('success'));
        } else {
            setMsg(res.data.msg);
            setDisabled(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormDatas({ ...formDatas, [name]: value });
        console.log(formDatas);
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormDatas({ ...formDatas, [name]: checked });
        console.log(formDatas);
    };

    const handleCheckAll = () => {
        const updatedFormDatas = { ...formDatas }; // Copie de l'état actuel des données du formulaire

        // Sélectionne tous les checkboxes à l'intérieur du formulaire avec l'id "form"
        const checkboxes = document.getElementById('form').querySelectorAll('input[type="checkbox"]');

        checkboxes.forEach(checkbox => {
            updatedFormDatas[checkbox.name] = true; // Coche chaque checkbox en mettant à jour formDatas
        });

        setFormDatas(updatedFormDatas);
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setFormDatas({ ...formDatas, [name]: value });

        if (value.length > 0) {
            if (name === 'prenom') {
                const filteredSuggestions = items.filter(item =>
                    item.prenom.toLowerCase().includes(value.toLowerCase())
                );
                setSuggestions(filteredSuggestions);
            }else{
                const filteredSuggestions = items.filter(item =>
                    item.nom.toLowerCase().includes(value.toLowerCase())
                );
                setSuggestions(filteredSuggestions);
            }
        } else {
            setSuggestions([]);
        }
    };

    const onSuggestionClick = (value) => {
        setFormDatas({ ...formDatas, nom: value.nom, prenom: value.prenom });
        setSuggestions([]);
        console.log(formDatas);
    };

    useEffect(() => {
        const fetchCSV = async () => {
            const response = await fetch('/assets/participations.csv');
            const reader = response.body.getReader();
            const result = await reader.read();
            const decoder = new TextDecoder('utf-8');
            const csv = decoder.decode(result.value);
            Papa.parse(csv, {
                header: true,
                complete: (results) => {
                    setItems(results.data);
                    console.log(results.data);
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                }
            });
        };

        fetchCSV();
    }, []);

    return (
        <div id="form" className="form container">
            <div className="w-100 text-end fs-2">
                <button className="text-center btn" onClick={() => { changeLanguage(t('lang_other')) }}>
                    {t('lang_other')}
                </button>
            </div>
            <form onSubmit={submit}>
                <div className="row mt-5">
                    <div className="col col-12">
                        <input className="form-control" required onChange={handleInputChange} type="text" name="entreprise" value={formDatas.entreprise || ''} placeholder={t('entreprise')} />
                    </div>
                    <div className="col col-6 mt-3">
                        <input className="form-control" required onChange={onChangeHandler} type="text" name="prenom" value={formDatas.prenom || ''} placeholder={t('prenom')} />
                        {suggestions.length > 0 && (
                            <ul>
                                {suggestions.map((suggestion, index) => (
                                    <li key={index} onClick={() => onSuggestionClick(suggestion, 'prenom')}>
                                        {suggestion.prenom}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="col col-6 mt-3">
                        <input className="form-control" required onChange={onChangeHandler} type="text" name="nom" value={formDatas.nom || ''} placeholder={t('nom')} />
                        {suggestions.length > 0 && (
                            <ul>
                                {suggestions.map((suggestion, index) => (
                                    <li key={index} onClick={() => onSuggestionClick(suggestion, 'nom')}>
                                        {suggestion.nom}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="col col-12 mt-3">
                        <input className="form-control" required onChange={handleInputChange} type="text" name="email" value={formDatas.email || ''} placeholder={t('email')} />
                    </div>
                    <div className="col col-12 mt-3">
                        <input className="form-control" required type="tel" name="tel" onChange={handleInputChange} placeholder={t('tel')} />
                    </div>
                    <h1 className="mt-5">
                        {t("conditions")}
                    </h1>
                    <button className="btn btn-warning mt-3 mb-3 w-50" type="button" onClick={handleCheckAll}>
                        {t('all')}
                    </button>
                    <div className="col col-12">

                    </div>
                    <div className="col col-1 mt-3">
                        <input id="q_4_1" className="checkbox" name="Valiente" onChange={handleCheckboxChange} checked={formDatas.Valiente || false} type="checkbox" />
                    </div>
                    <div className="col col-11 mt-3">
                        <label htmlFor="q_4_1">Valientea : {t("Valientea")}</label>
                    </div>
                    <div className="col col-1 mt-3">
                        <input id="q_4_1_2" className="checkbox" name="Valientea" onChange={handleCheckboxChange} checked={formDatas.Valientea || false} type="checkbox" />
                    </div>
                    <div className="col col-11 mt-3">
                        <label htmlFor="q_4_1_2">Valiente : {t("Valiente")}</label>
                    </div>
                    <div className="col col-1 mt-3">
                        <input id="q_5" className="checkbox" name="Le_Flambant" onChange={handleCheckboxChange} checked={formDatas.Le_Flambant || false} type="checkbox" />
                    </div>
                    <div className="col col-11 mt-3">
                        <label htmlFor="q_5">Le Flambant : {t("Le_Flambant")}</label>
                    </div>
                    <div className="col col-1 mt-3">
                        <input id="q_5_3" className="checkbox" name="Valiente_2" onChange={handleCheckboxChange} checked={formDatas.Valiente_2 || false} type="checkbox" />
                    </div>
                    <div className="col col-11 mt-3">
                        <label htmlFor="q_5_3">Valiente : {t("Valiente_2")}</label>
                    </div>
                    <div className="col col-1 mt-3">
                        <input id="q_7" className="checkbox" name="Pure_CBD" onChange={handleCheckboxChange} checked={formDatas.Pure_CBD || false} type="checkbox" />
                    </div>
                    <div className="col col-11 mt-3">
                        <label htmlFor="q_7">Pure CBD : {t('Pure_CBD')}</label>
                    </div>
                    <div className="col col-1 mt-3">
                        <input id="q_8" className="checkbox" name="Peter_Engels" onChange={handleCheckboxChange} checked={formDatas.Peter_Engels || false} type="checkbox" />
                    </div>
                    <div className="col col-11 mt-3">
                        <label htmlFor="q_8">Peter Engels : {t("Peter_Engels")}</label>
                    </div>
                    <div className="col col-1 mt-3">
                        <input id="q_9" className="checkbox" name="MG_weekend" onChange={handleCheckboxChange} checked={formDatas.MG_weekend || false} type="checkbox" />
                    </div>
                    <div className="col col-11 mt-3">
                        <label htmlFor="q_9">Van Mossel : {t("MG_weekend")}</label>
                    </div>
                    {
                        (formDatas.MG_weekend) ?
                            <>
                                <div className="col col-6 m-5">
                                    <label htmlFor="sub_q_1">{t("actuel_marque")}</label>
                                    <input type="text" name="actuel_marque" value={(formDatas.actuel_marque) ? formDatas.actuel_marque : ''} onChange={handleInputChange} id="sub_q_1" required className="form-control" />
                                    <label className="mt-3" htmlFor="actuel_model">{t('actuel_model')}</label>
                                    <input type="text" name="actuel_model" value={(formDatas.actuel_model) ? formDatas.actuel_model : ''} onChange={handleInputChange} id="actuel_model" required className="form-control" />
                                    <div className="mt-3">
                                        <label htmlFor="sub_q_2">{t("entrerpise_v")}</label>
                                        <br />
                                        <input className="m-2" type="checkbox" name="entrerpise_v" onChange={handleCheckboxChange} checked={formDatas.entrerpise_v || false} />
                                        <label htmlFor="oui_q_2" className="mt-3">{t('oui')}</label>
                                        <br />
                                        {
                                            (formDatas.entrerpise_v) ?
                                                <>
                                                    <label htmlFor="entreprise_name" className="mt-3">{t('entreprise_name')}</label>
                                                    <input required={formDatas.entrerpise_v} className="form-control" id="entreprise_name" value={(formDatas.entreprise_name) ? formDatas.entreprise_name : ''} name="entreprise_name" onChange={handleInputChange} type="text" />
                                                </>
                                                :
                                                ''
                                        }
                                        <hr />
                                    </div>
                                    <div className="col mt-3">
                                        <label htmlFor="sub_q_3">{t("nombre_d_f_v")}</label>
                                        <input id="sub_q_3" type="number" required min={0} className="form-control" value={formDatas.nombre_d_f_v} name="nombre_d_f_v" onChange={handleInputChange} />
                                    </div>
                                    <div className="row">
                                        <div className="col col-1 mt-3">
                                            <input id="sub_q_4" className="checkbox" type="checkbox" checked={formDatas.essais || false} name="essais" onChange={handleCheckboxChange} />
                                        </div>
                                        <div className="col col-11 mt-3">
                                            <label htmlFor="sub_q_4">{t("msg_more")}</label>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            ''
                    }
                    <div className="col col-12">

                    </div>
                    <div className="col col-1 mt-3">
                        <input id="q_10" className="checkbox" name="Shine_Leathercosmetics" onChange={handleCheckboxChange} checked={formDatas.Shine_Leathercosmetics || false} type="checkbox" />
                    </div>
                    <div className="col col-11 mt-3">
                        <label htmlFor="q_10">{t("Shine_Leathercosmetics")}</label>
                    </div>
                    <div className="col col-1 mt-3">
                        <input required id="q_13" className="checkbox" name="autorise" onChange={handleCheckboxChange} checked={formDatas.autorise || false} type="checkbox" />
                    </div>
                    <div className="col col-11 mt-3">
                        <label htmlFor="q_13" className="t-bold">{t("autorise")}</label>
                    </div>
                    <button style={{ marginBottom: '220px' }} disabled={disabled} type="submit" className="btn mt-5 btn-primary">{t('enregistrer')}</button>
                    <span style={{ marginBottom: '220px' }} className="err mt-3 fs-2">{t(msg)}</span>
                </div>
            </form>
            <div className="footer">

            </div>
        </div>
    );
}
