import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Form from './components/pages/From';
import TankYou from './components/pages/TankYou';
import Code from './components/pages/Code';
import Game from './components/pages/Game';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Form/>}/>
        <Route exact path='/thnx' element={<TankYou/>}/>
        <Route exact path='/code' element={<Code/>}/>
        <Route exact path='/game' element={<Game/>}/>
      </Routes>
    </Router>
  );
}

export default App;